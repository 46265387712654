<template>
  <div>
<!--
    <h1>Настройки</h1>
            <router-link
            class="next_btn"
            to="/fo_select">
            К выбору региона
        </router-link>
-->

<div id="print-cont">
<div id="print" @[dblClickEvent]="goBack">
    <div id="message-cont">
        <div id="print-message" v-show="flMess">
            <div id="prt-mess-text">
                {{messTxt}}
            </div>
            <div id="mess-but" @[clickEvent]="closeModal">
                ОК
            </div>
        </div>
    </div>
    <div class="print-title">
        Результаты расчета финансовых затрат на терапию онкологических заболеваний.
    </div>
    <div id="print-body">
      <div class="page-break">
        <div class="print-subtitle">
            Регион: <span id="region">{{regName}}</span><br>
            Нозология: <span id="print-indic">{{nosologyName}}</span>
        </div>
        <div class="print-subtitle">
            Расчет стоимости для одного пациента
        </div>
        <div class="print-subtitle">
            Вес пациента: <span id="pat-weight">{{averWeight}}</span><br>
        </div>
        <div class="print-subtitle">
            Стоимость одной инфузии
        </div>
        <div id="table1">
            <table align="center">
                <tbody v-html="table1">
                </tbody>
            </table>
        </div> 
<br><br>

        <div class="print-subtitle">
            Стоимость курса для одного пациента (без участия/с участием в программе целевой передачи Ипилимумаб), руб.
        </div>
        <div id="table2">
            <table align="center">
                <tbody v-html="table2">
                </tbody>
            </table>
          <div class="table-remark" v-if="this.flOptim">
            * В скобках указана стоимость курса с участием в программе целевой передачи Ипилимумаб
          </div>
        </div> 
      </div>
<br><br>
      <div class="page-break">
        <div class="print-subtitle">
            Стоимость курса для группы пациентов (без участия/с участием в программе целевой передачи Ипилимумаб), руб.
        </div>
        <div class="print-subtitle">
            Кол-во пациентов: <span id="patnum">{{patNum}}</span><br>
            Средний вес пациентов: <span id="aver-weight">{{averWeight}}</span>
        </div>
        <div id="table3">
            <table align="center">
                <tbody v-html="table3">
                </tbody>
            </table>
          <div class="table-remark" v-if="this.flGroupOptim">
            * В скобках указана стоимость курса с участием в программе целевой передачи Ипилимумаб
          </div>
        </div> 
      </div>
    </div>
</div>
</div>

  </div>
</template>

<script>
import dataStore from '@/views/data';
import schemas from '@/views/schemas';
import drugList from '@/views/drugStore';
import initList from '@/views/initPrice';


export default {
  components:{

  },
  data: function() {
    return {
      flOptim: false,
      flGroupOptim: false,
      messTxt: 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.',
      flMess: false,
      flDouble: false,
      dblClickEvent: dataStore.dblClickEvent,
      clickEvent: dataStore.clickEvent
    }
  },
  methods: {
    testPrint: function() {
//console.log('print')      
    },
    closeModal: function() {
      this.flMess = false;
      this.flDouble = true;
    },
    goBack: function() {
      if(this.flDouble==false) return;
      this.flDouble = false;
      dataStore.flInterest = dataStore.flInterestSave;
      dataStore.currWeeks = dataStore.currWeeksSave;
      this.$router.push({path: 'groupcalc'});
    }
  },

  computed: {

    patNum() {

//dataStore.totPats = 10;
      return dataStore.totPats;
    },

    averWeight() {
      return dataStore.patWeight;
    },

    regName(){
      
      let name, ref;

//!!DEBUG
//dataStore.currRegion = 5;
      if(dataStore.selectedRegs[dataStore.currRegion - 1]) name = dataStore.selectedRegs[dataStore.currRegion - 1].regName;
      else {
        name = 'Не выбран';
      }
//return 'Белгородская область'
      return name;
    },
    nosologyName(){
      if(dataStore.currIndic==6) return dataStore.nosology[0];
      return dataStore.nosology[dataStore.currIndic];
    },

    table3() {

      let currMethod = dataStore.currMethod;
      let methData = schemas[currMethod];
      let table;
      let titleCombined = `          
          <tr>
            <td rowspan="2" style="min-width:98px">Препарат</td>
            <td colspan="3">12 недель</td>
            <td colspan="3">24 недели</td>
            <td colspan="3">36 недель</td>
            <td colspan="3">52 недели</td>
          </tr>
          <tr>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
          </tr>
`;

      let combiLine = `
          <tr>
            <td>Ниволумаб 100/40мг 1 мг/кг</td>
            <td>0+8</td>
            <td>273 472</td>
            <td rowspan="2">4 368 432 (2 320 952)*</td>
            <td>0+8</td>
            <td>273 472</td>
            <td rowspan="2">4 368 432 (2 320 952)*</td>
            <td>0+8</td>
            <td>273 472</td>
            <td rowspan="2">4 368 432 (2 320 952)*</td>
            <td>0+8</td>
            <td>273 472</td>
            <td rowspan="2">4 368 432 (2 320 952)*</td>
          </tr>
          <tr>
            <td>Ипилимумаб 50 мг 3 мг/кг</td>
            <td>20</td>
            <td>4 094 960 (2 047 480)*</td>
            <td>20</td>
            <td>4 094 960 (2 047 480)*</td>
            <td>20</td>
            <td>4 094 960 (2 047 480)*</td>
            <td>20</td>
            <td>4 094 960 (2 047 480)*</td>
          </tr>`;

        let singleLine = `  
          <tr>
            <td>Ниволумаб 100/40мг 480 мг</td>
            <td>-</td>
            <td colspan="2">-</td>
            <td>12+6</td>
            <td colspan="2">1 230 624</td>
            <td>12+6</td>
            <td colspan="2">1 230 624</td>
            <td>12+6</td>
            <td colspan="2">1 230 624</td>
          </tr>
      `;
        let totalLine = `  
          <tr>
            <td>Итого</td>
            <td colspan="3">4 368 432 (2 320 952)*</td>
            <td colspan="3">4 368 432 (2 320 952)*</td>
            <td colspan="3">4 368 432 (2 320 952)*</td>
            <td colspan="3">4 368 432 (2 320 952)*</td>
          </tr>
      `;

      let currLine, drug, name, name1, singleName, dose, dose1, flIpi = 0, flNivol = 0, priceCombi, weekData = [12, 24, 36, 52], weeks, currOptLine, priceOptCombi, 
        packs = [], packs1 = [], singlePacks = [], price1 = [], price = [], 
        singlePrice = [], optPrice = [], singleOptPrice = [], priceStr = [],
        singlePriceStr = [], total = [], totalOpt = [], totalStr = [], priceCombiStr = [];

      combiLine = ``; singleLine = ``;

      this.flGroupOptim = false;
// Для группы пациентов
      if(currMethod<2 || currMethod >2) this.flGroupOptim = true;// Отображение уменьшенной цены возможно
      // только для методов, которые относятся к терапии меланомы, ГЦК и РЛ
// Для одного пациента
//      if(currMethod<2) this.flGroupOptim = true;// Отображение уменьшенной цены возможно
      // только для методов, которые относятся к терапии меланомы и ГЦК
      if(dataStore.currMethod<6) {// Методы, отображаемые с комбо-ячейками для ниво+ипили
        priceCombi = []; priceOptCombi = [];
        for(let weekSet=0 ; weekSet<4 ; weekSet++) {
          weeks = weekData[weekSet];
          priceCombi[weeks] = 0; priceOptCombi[weeks] = 0;
          total[weeks] = 0; totalOpt[weeks] = 0;
        }
        for(let cnt=0, len=methData.prepData.length ; cnt<len ; cnt++) {
          drug = methData.prepData[cnt].prepNum[0];
          name = drugList[drug].name;
          if(drug==3) {// Ипилимумаб
            flIpi++;
          }
          if(drug==1 || drug==2) { // Костыль для Ниволумаба
            flNivol++;
            name1 = name + '100/40 мг';
            dose1 = '<br>' + methData.prepData[cnt].dose + ' мг/кг';
            name1 = name1 + ' ' + dose1;
            singleName = name1;
          } else {
            name = name + ' ' + drugList[drug].dose + ' мг';
            dose = '' + methData.prepData[cnt].dose;
            if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
            if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
            if(methData.prepData[cnt].mode=='s') dose = dose + ' мг/м<sup>2</sup>';
            name = name + '<br>' + dose;
            singleName = name;
          }
// Данные по неделям терапии
          for(let weekSet=0 ; weekSet<4 ; weekSet++) {
            weeks = weekData[weekSet];
            dataStore.currWeeks = weeks;
            currLine = dataStore.calcCursLine(cnt, false); 
            currOptLine = dataStore.calcCursLine(cnt, true); 
            if(drug==3) {// Ипилимумаб
              priceCombi[weeks] += parseInt(currLine.groupPrice);
              priceOptCombi[weeks] += parseInt(currOptLine.groupPrice);
            }
            if(drug==1 || drug==2) { // Костыль для Ниволумаба
              if(flNivol==1) {
                priceCombi[weeks] += parseInt(currLine.groupPrice);
                priceOptCombi[weeks] += parseInt(currOptLine.groupPrice);
              }
              packs1[weeks] = '' + currLine.cursPacks[0]*this.patNum + '+' + currLine.cursPacks[1]*this.patNum;
              price1[weeks] = dataStore.padDigits(currLine.groupPrice);
              singlePrice[weeks] = currLine.groupPrice;
              if(currLine.cursPrice>0) {
                singlePacks[weeks] = '' + currLine.cursPacks[0]*this.patNum + '+' + currLine.cursPacks[1]*this.patNum;
                singlePriceStr[weeks] = ''+dataStore.padDigits(singlePrice[weeks]);
              } else {
                singlePacks[weeks] = '-';
                singlePriceStr[weeks] = '-';
              }
            } else {
              packs[weeks] = '' + currLine.cursPacks[0]*this.patNum;
              price[weeks] = dataStore.padDigits(currLine.groupPrice);
              optPrice[weeks] = dataStore.padDigits(currOptLine.groupPrice);
              singlePacks[weeks] = '' + currLine.cursPacks[0]*this.patNum;
              singlePrice[weeks] = dataStore.padDigits(currLine.groupPrice);
              singleOptPrice[weeks] = dataStore.padDigits(currOptLine.groupPrice);
//              singlePriceStr[weeks] = ''+singlePrice[weeks]+'<br>('+singleOptPrice[weeks]+')*';
              singlePriceStr[weeks] = ''+singlePrice[weeks];
            }
            if(parseInt(currLine.groupPrice)) total[weeks] += parseInt(currLine.groupPrice);
            if(parseInt(currOptLine.groupPrice)) totalOpt[weeks] += parseInt(currOptLine.groupPrice);
            if(this.flGroupOptim) {
              priceCombiStr[weeks] = `${dataStore.padDigits(priceCombi[weeks])}<br>(${dataStore.padDigits(priceOptCombi[weeks])})*`;
              totalStr[weeks] = '' + dataStore.padDigits(total[weeks]) + ' (' + dataStore.padDigits(totalOpt[weeks]) + ')*';
              priceStr[weeks] = `${price[weeks]}<br>(${optPrice[weeks]})*`;
            }
            else {
              priceCombiStr[weeks] = `${dataStore.padDigits(priceCombi[weeks])}`;
              totalStr[weeks] = '' + dataStore.padDigits(total[weeks]);
              priceStr[weeks] = `${price[weeks]}`;
            }
 
          }
// Шаблон комбинированной строки
          if(flNivol==1 && flIpi==1 && cnt<2) { // Костыль!! Предполагается, что комбинация
            // ниволумаб+пилимумаб во всех схемах идет на первых позициях в схеме 
            combiLine = combiLine + `
              <tr>
                <td>${name1}</td>
                <td>${packs1[12]}</td>
                <td>${price1[12]}</td>
                <td rowspan="2">${priceCombiStr[12]}</td>
                <td>${packs1[24]}</td>
                <td>${price1[24]}</td>
                <td rowspan="2">${priceCombiStr[24]}</td>
                <td>${packs1[36]}</td>
                <td>${price1[36]}</td>
                <td rowspan="2">${priceCombiStr[36]}</td>
                <td>${packs1[52]}</td>
                <td>${price1[52]}</td>
                <td rowspan="2">${priceCombiStr[52]}</td>
              </tr>
              <tr>
                <td>${name}</td>
                <td>${packs[12]}</td>
                <td>${priceStr[12]}</td>
                <td>${packs[24]}</td>
                <td>${priceStr[24]}</td>
                <td>${packs[36]}</td>
                <td>${priceStr[36]}</td>
                <td>${packs[52]}</td>
                <td>${priceStr[52]}</td>
              </tr>`;
          }
// Шаблон строк без объединенных ячеек           
          if(cnt>1) {
            singleLine = singleLine + `
              <tr>
                <td>${singleName}</td>
                <td>${singlePacks[12]}</td>
                <td colspan="2">${singlePriceStr[12]}</td>
                <td>${singlePacks[24]}</td>
                <td colspan="2">${singlePriceStr[24]}</td>
                <td>${singlePacks[36]}</td>
                <td colspan="2">${singlePriceStr[36]}</td>
                <td>${singlePacks[52]}</td>
                <td colspan="2">${singlePriceStr[52]}</td>
              </tr>
          `;
          }
        }
        totalLine = `  
          <tr>
            <td>Итого</td>
            <td colspan="3">${totalStr[12]}</td>
            <td colspan="3">${totalStr[24]}</td>
            <td colspan="3">${totalStr[36]}</td>
            <td colspan="3">${totalStr[52]}</td>
          </tr>
      `;

      }
//-------------------------------------------------------------
      if(dataStore.currMethod>=6) {// Методы, отображаемые без комбинированных строк

      }

      table = titleCombined + combiLine + singleLine + totalLine;
      return table;



    },

//==============================================================
    table2() {

//!!DEBUG
// dataStore.currIndic = 0;
// dataStore.currMethod = 5;

      let currMethod = dataStore.currMethod;
      let methData = schemas[currMethod];
      let table;
      let titleCombined = `          
          <tr>
            <td rowspan="2" style="min-width:98px">Препарат</td>
            <td colspan="3">12 недель</td>
            <td colspan="3">24 недели</td>
            <td colspan="3">36 недель</td>
            <td colspan="3">52 недели</td>
          </tr>
          <tr>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
            <td>Кол-во флаконов</td>
            <td colspan="2">Стоимость, руб.</td>
          </tr>
`;
      let combiLine = ``;
      let singleLine = ``;
      let totalLine = ``;

      let currLine, drug, name, name1, singleName, dose, dose1, flIpi = 0, flNivol = 0, priceCombi, weekData = [12, 24, 36, 52], weeks, currOptLine, priceOptCombi, 
        packs = [], packs1 = [], singlePacks = [], price1 = [], price = [], 
        singlePrice = [], optPrice = [], singleOptPrice = [], priceStr = [],
        singlePriceStr = [], total = [], totalOpt = [], totalStr = [], priceCombiStr = [];

      combiLine = ``; singleLine = ``;

      this.flOptim = false;
// Для группы пациентов
//      if(currMethod<2 || currMethod >2) this.flOptim = true;// Отображение уменьшенной цены возможно
      // только для методов, которые относятся к терапии меланомы, ГЦК и РЛ
// Для одного пациента
      if(currMethod<2) this.flOptim = true;// Отображение уменьшенной цены возможно
      // только для методов, которые относятся к терапии меланомы и ГЦК
      if(dataStore.currMethod<6) {// Методы, отображаемые с комбо-ячейками для ниво+ипили
        priceCombi = []; priceOptCombi = [];
        for(let weekSet=0 ; weekSet<4 ; weekSet++) {
          weeks = weekData[weekSet];
          priceCombi[weeks] = 0; priceOptCombi[weeks] = 0;
          total[weeks] = 0; totalOpt[weeks] = 0;
        }
        for(let cnt=0, len=methData.prepData.length ; cnt<len ; cnt++) {
          drug = methData.prepData[cnt].prepNum[0];
          name = drugList[drug].name;
          if(drug==3) {// Ипилимумаб
            flIpi++;
          }
          if(drug==1 || drug==2) { // Костыль для Ниволумаба
            flNivol++;
            name1 = name + '100/40 мг';
            dose1 = '<br>' + methData.prepData[cnt].dose + ' мг/кг';
            name1 = name1 + ' ' + dose1;
            singleName = name1;
          } else {
            name = name + ' ' + drugList[drug].dose + ' мг';
            dose = '' + methData.prepData[cnt].dose;
            if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
            if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
            if(methData.prepData[cnt].mode=='s') dose = dose + ' мг/м<sup>2</sup>';
            name = name + '<br>' + dose;
            singleName = name;
          }
// Данные по неделям терапии
          for(let weekSet=0 ; weekSet<4 ; weekSet++) {
            weeks = weekData[weekSet];
            dataStore.currWeeks = weeks;
            currLine = dataStore.calcCursLine(cnt, false); 
            currOptLine = dataStore.calcCursLine(cnt, true); 
            if(drug==3) {// Ипилимумаб
              priceCombi[weeks] += parseInt(currLine.cursPrice);
              priceOptCombi[weeks] += parseInt(currOptLine.cursPrice);
            }
            if(drug==1 || drug==2) { // Костыль для Ниволумаба
              if(flNivol==1) {
                priceCombi[weeks] += parseInt(currLine.cursPrice);
                priceOptCombi[weeks] += parseInt(currOptLine.cursPrice);
              }
              packs1[weeks] = '' + currLine.cursPacks[0] + '+' + currLine.cursPacks[1];
              price1[weeks] = dataStore.padDigits(currLine.cursPrice);
              singlePrice[weeks] = dataStore.padDigits(currLine.cursPrice);
              if(currLine.cursPrice>0) {
                singlePacks[weeks] = '' + currLine.cursPacks[0] + '+' + currLine.cursPacks[1];
                singlePriceStr[weeks] = ''+singlePrice[weeks];
              } else {
                singlePacks[weeks] = '-';
                singlePriceStr[weeks] = '-';
              }
            } else {
              packs[weeks] = '' + currLine.cursPacks[0];
              price[weeks] = dataStore.padDigits(currLine.cursPrice);
              optPrice[weeks] = dataStore.padDigits(currOptLine.cursPrice);
              singlePacks[weeks] = '' + currLine.cursPacks[0];
              singlePrice[weeks] = dataStore.padDigits(currLine.cursPrice);
              singleOptPrice[weeks] = dataStore.padDigits(currOptLine.cursPrice);
//              singlePriceStr[weeks] = ''+singlePrice[weeks]+'<br>('+singleOptPrice[weeks]+')*';
              singlePriceStr[weeks] = ''+singlePrice[weeks];
            }
            total[weeks] += parseInt(currLine.cursPrice);
            totalOpt[weeks] += parseInt(currOptLine.cursPrice);
            if(this.flOptim) {
              priceCombiStr[weeks] = `${dataStore.padDigits(priceCombi[weeks])}<br>(${dataStore.padDigits(priceOptCombi[weeks])})*`;
              totalStr[weeks] = '' + dataStore.padDigits(total[weeks]) + ' (' + dataStore.padDigits(totalOpt[weeks]) + ')*';
              priceStr[weeks] = `${price[weeks]}<br>(${optPrice[weeks]})*`;
            }
            else {
              priceCombiStr[weeks] = `${dataStore.padDigits(priceCombi[weeks])}`;
              totalStr[weeks] = '' + dataStore.padDigits(total[weeks]);
              priceStr[weeks] = `${price[weeks]}`;
            }
 
          }
// Шаблон комбинированной строки
          if(flNivol==1 && flIpi==1 && cnt<2) { // Костыль!! Предполагается, что комбинация
            // ниволумаб+пилимумаб во всех схемах идет на первых позициях в схеме 
            combiLine = combiLine + `
              <tr>
                <td>${name1}</td>
                <td>${packs1[12]}</td>
                <td>${price1[12]}</td>
                <td rowspan="2">${priceCombiStr[12]}</td>
                <td>${packs1[24]}</td>
                <td>${price1[24]}</td>
                <td rowspan="2">${priceCombiStr[24]}</td>
                <td>${packs1[36]}</td>
                <td>${price1[36]}</td>
                <td rowspan="2">${priceCombiStr[36]}</td>
                <td>${packs1[52]}</td>
                <td>${price1[52]}</td>
                <td rowspan="2">${priceCombiStr[52]}</td>
              </tr>
              <tr>
                <td>${name}</td>
                <td>${packs[12]}</td>
                <td>${priceStr[12]}</td>
                <td>${packs[24]}</td>
                <td>${priceStr[24]}</td>
                <td>${packs[36]}</td>
                <td>${priceStr[36]}</td>
                <td>${packs[52]}</td>
                <td>${priceStr[52]}</td>
              </tr>`;
          }
// Шаблон строк без объединенных ячеек           
          if(cnt>1) {
            singleLine = singleLine + `
              <tr>
                <td>${singleName}</td>
                <td>${singlePacks[12]}</td>
                <td colspan="2">${singlePriceStr[12]}</td>
                <td>${singlePacks[24]}</td>
                <td colspan="2">${singlePriceStr[24]}</td>
                <td>${singlePacks[36]}</td>
                <td colspan="2">${singlePriceStr[36]}</td>
                <td>${singlePacks[52]}</td>
                <td colspan="2">${singlePriceStr[52]}</td>
              </tr>
          `;
          }
        }
        totalLine = `  
          <tr>
            <td>Итого</td>
            <td colspan="3">${totalStr[12]}</td>
            <td colspan="3">${totalStr[24]}</td>
            <td colspan="3">${totalStr[36]}</td>
            <td colspan="3">${totalStr[52]}</td>
          </tr>
      `;

      }
//-------------------------------------------------------------
      if(dataStore.currMethod>=6) {// Методы, отображаемые без комбинированных строк

      }

      table = titleCombined + combiLine + singleLine + totalLine;
      return table;
    },

    table1() {

      let table = `
        `;  

//!!DEBUG
//dataStore.currMethod = 5;
      let currMethod = dataStore.currMethod;
      let methData = schemas[currMethod];
      let titleCombined = `          
        <tr>
          <td style="min-width:98px">Препарат</td>
          <td>Цена за упаковку, руб.</td>
          <td>Доза, мг</td>
          <td>Кол-во флаконов</td>
          <td colspan="2">Стоимость одной инфузии, руб.</td>
        </tr>
      `;

      let combiLine = `
          <tr>
            <td>Ниволумаб 100/40мг 1 мг/кг</td>
            <td>85 460/34 184</td>
            <td>75</td>
            <td>0+2</td>
            <td>68 368</td>
            <td rowspan="2">1 092 108</td>
          </tr>
          <tr>
            <td>Ипилимумаб 50 мг 3 мг/кг</td>
            <td>204 748</td>
            <td>225</td>
            <td>5</td>
            <td>1 023 740</td>
          </tr>`; 

      let singleLine = `
          <tr>
            <td>Ниволумаб 100/40мг 480 мг</td>
            <td>85 460/34 184</td>
            <td>480</td>
            <td>4+2</td>
            <td colspan="2">410 208</td>
          </tr>`; 

      let currLine, drug, name, name1, singleName, dose, dose1, flIpi = 0, flNivol = 0, priceCombi, currOptLine, priceOptCombi, flCustomPrice = false,
        packs, packs1, singlePacks, price1, price, packPrice, packPrice1, singleLinePackPrice,
        singlePrice, priceStr, singleDose1, singleDose, singleLineDose,
        singlePriceStr, priceCombiStr = [];

      combiLine = ``; singleLine = ``;

      if(dataStore.currMethod<6) {// Методы, отображаемые с комбо-ячейками для ниво+ипили
        priceCombi = 0; 
        for(let cnt=0, len=methData.prepData.length ; cnt<len ; cnt++) {
          flCustomPrice = false;
          drug = methData.prepData[cnt].prepNum[0];
          if(initList[drug].price != drugList[drug].price) flCustomPrice = true;
          name = drugList[drug].name;
          if(drug==3) {// Ипилимумаб
            flIpi++;
          }
          if(drug==1 || drug==2) { // Костыль для Ниволумаба
            flNivol++;
            name1 = name + '100/40 мг';
            dose1 = '<br>' + methData.prepData[cnt].dose + ' мг/кг';
            name1 = name1 + ' ' + dose1;
            singleName = name1;
          } else {
            name = name + ' ' + drugList[drug].dose + ' мг';
            dose = '' + methData.prepData[cnt].dose;
            if(methData.prepData[cnt].mode=='a') dose = dose + ' мг';
            if(methData.prepData[cnt].mode=='m') dose = dose + ' мг/кг';
            if(methData.prepData[cnt].mode=='s') dose = dose + ' мг/м<sup>2</sup>';
            name = name + '<br>' + dose;
            singleName = name;
          }
// Данные по неделям терапии
          dataStore.currWeeks = 52;
          currLine = dataStore.calcCursLine(cnt, false); 
          if(drug==3) {// Ипилимумаб
            priceCombi += parseInt(currLine.singlePrice);
          }
          if(drug==1 || drug==2) { // Костыль для Ниволумаба
            if(flNivol==1) {
              priceCombi += parseInt(currLine.singlePrice);
            }
            singleDose1 = currLine.dose;
            packPrice1 = '' + dataStore.padDigits(dataStore.applyRegData(drugList[1].price, flCustomPrice))
                            + '/'
                            + dataStore.padDigits(dataStore.applyRegData(drugList[2].price, flCustomPrice));
            packs1 = '' + currLine.singlePacks[0] + '+' + currLine.singlePacks[1];
            price1 = dataStore.padDigits(currLine.singlePrice);
            singlePrice = dataStore.padDigits(currLine.singlePrice);
            singleLineDose = currLine.dose;
            if(currLine.singlePrice>0) {
              singlePacks = '' + currLine.singlePacks[0] + '+' + currLine.singlePacks[1];
              singlePriceStr = ''+singlePrice;
              singleLinePackPrice = '' + dataStore.padDigits(dataStore.applyRegData(drugList[1].price, flCustomPrice))
                            + '/'
                            + dataStore.padDigits(dataStore.applyRegData(drugList[2].price, flCustomPrice));
            } else {
              singlePacks = '-';
              singlePriceStr = '-';
            }
          } else {
            singleLineDose = currLine.dose;
            singleDose = currLine.dose;
            packPrice = dataStore.padDigits(dataStore.applyRegData(drugList[drug].price, flCustomPrice));
            singleLinePackPrice = packPrice;
            packs = '' + currLine.cursPacks[0];
            price = dataStore.padDigits(currLine.singlePrice);
            singlePacks = '' + currLine.cursPacks[0];
            singlePrice = dataStore.padDigits(currLine.singlePrice);
            singlePriceStr = ''+singlePrice;
          }
          priceCombiStr = `${dataStore.padDigits(priceCombi)}`;
          priceStr = `${price}`;
// Шаблон комбинированной строки
          if(flNivol==1 && flIpi==1 && cnt<2) { // Костыль!! Предполагается, что комбинация
            // ниволумаб+пилимумаб во всех схемах идет на первых позициях в схеме 
            combiLine = combiLine + `
              <tr>
                <td>${name1}</td>
                <td>${packPrice1}</td>
                <td>${singleDose1}</td>
                <td>${packs1}</td>
                <td>${price1}</td>
                <td rowspan="2">${priceCombiStr}</td>
              </tr>
              <tr>
                <td>${name}</td>
                <td>${packPrice}</td>
                <td>${singleDose}</td>
                <td>${packs}</td>
                <td>${priceStr}</td>
              </tr>`; 
          }

// Шаблон строк без объединенных ячеек           
          if(cnt>1) {
            singleLine = singleLine + `
            <tr>
              <td>${singleName}</td>
              <td>${singleLinePackPrice}</td>
              <td>${singleLineDose}</td>
              <td>${singlePacks}</td>
              <td colspan="2">${singlePriceStr}</td>
            </tr>`;
          } 
        }

      }

      table = titleCombined + combiLine + singleLine;

      return table
    }
  },
  mounted(){

    dataStore.flInterestSave = dataStore.flInterest;
    dataStore.currWeeksSave =  dataStore.currWeeks;
      setTimeout(()=> {
          dataStore.flModal = 1;
          window.focus();
//          alert('Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.');
          window.print();          
          if(dataStore.flIpad) {
              this.messTxt = 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.';
              this.messTxt += '<br>Если ваш планшет не нашел доступный принтер для печати, воспользуйтесь пиктограммой в виде прямоугольника в верхней строке браузера<br><br><img src="img/pict.png"><br><br>для отправки результатов по почте или преобразования в другой удобный формат';
          } else {
              this.messTxt = 'Для возвращения к калькулятору после печати, выполните двойной тап или клик на экране с таблицей.';
          }
          setTimeout(()=>{
            this.flMess = true;
              }, 1000);
      },1000);      
  }
}
</script>
<style scoped>
#print-cont {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.next_btn{
  margin: auto;
  margin-top: 15px;
  display: block;
  width: 200px;
  background-color: #03417A;
  text-decoration: none;
  color: #fff;
  font-family: 'Roboto';
  text-align: center;
  padding: 12px;
}
#print {
    background-color: white;
    width:100%;
    height: 1000px !important;
    overflow: visible;
}

#print td {
    border: 1px solid;
    padding: 5px;
    text-align: center;
    min-width: 65px;
    height: 22px;
}

#print table {
    width: 920px;
    border: 1px solid;
    border-spacing: 0;
    margin-left: 10px;}

.print-title {
    text-align: center;
    width:800px;
    margin: auto;
    font-family: arial;
    font-stretch: condensed;
    font-size: 18pt;
}

.print-subtitle {
    margin-left: 12px;
    width: 100%;
    font-family: arial;
    font-stretch: condensed;
    font-size: 13pt;
    margin-top: 5px;
    margin-bottom: 5px;
}

.print-total {
    text-align: right;
    width:920px;
    margin: auto;
    font-family: arial;
    font-stretch: condensed;
    font-size: 15pt;
    margin-top: 10px;
}

#print-but {
   padding: 8px;
   border: 1px solid black;
   border-radius: 4px;
   margin-top: 10px;
   width: 76px;
   text-align: center;
   position: relative;
   left: 825px; 
   cursor: pointer;
}

.table-remark {
  font-family: arial;
  font-size: 11px;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 4px;
}

.page-break {
  page-break-after: always;
  page-break-inside: avoid;
}

</style>
